import React , { Fragment } from 'react';
import Head                 from 'next/head';
import { useTranslation } from 'next-i18next';

const PageMeta = ({ title, description, image, media, type='website', canonical, noindex }) =>
{
    const { t } = useTranslation() ;

    return (
        <Head>
            <title>{title}</title>
            <meta name="description" content={description}/>
            <meta property="og:site_name" content={ t(`${process.env.APP_LOCALIZATION}:country.brand`) } />
            {title && <meta property="og:title" content={title}/>}
            {description && <meta property="og:description" content={description}/>}
            {image && <meta property="og:image" content={image}/>}
            { ( media && media.length > 0 ) &&
                media.map( image => {
                    const { contentUrl , encodingFormat, height , thumbnail , width } = image ;

                    let imgContentUrl = contentUrl ;
                    let imgHeight = height ;
                    let imgWidth = width ;

                    if( thumbnail )
                    {
                        const { contentUrl : thumbContentUrl , height : thumbHeight , width : thumbWidth } = thumbnail ;

                        imgContentUrl = thumbContentUrl ;
                        imgHeight = thumbHeight ;
                        imgWidth = thumbWidth ;
                    }

                    return (
                        <Fragment key={imgContentUrl}>
                            <meta property="og:image" content={imgContentUrl} />
                            { encodingFormat && <meta property="og:image:type" content={encodingFormat}/>}
                            { imgHeight && <meta property="og:image:height" content={imgHeight}/>}
                            { imgWidth && <meta property="og:image:width" content={imgWidth}/>}
                        </Fragment>
                    );
                })
            }
            {type && <meta property="og:type" content={type} />}
            {canonical && <meta property="og:url" content={canonical}/>}
            {canonical && <link rel="canonical" href={canonical}/>}
            {noindex && <meta name="robots" content="noindex"/>}
        </Head>
    );
};

export default PageMeta;
